<template>
  <div>
    <DatePickerWidget v-model="date" />
  </div>
</template>

<script>
import DatePickerWidget from "@/modules/UiKit/components/DatePicker/DatePickerWidget";
export default {
  name: "DatePickerPage",
  mixins: [],
  props: {},
  components: { DatePickerWidget },
  data() {
    return {
      date: new Date()
    };
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
</style>
